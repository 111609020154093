<template>
  <div>
    <slot name="createCollectionButton" />

    <!-- grid -->
    <div
      v-if="librariesList.length"
      class="sourcery__grid">
      <div
        v-for="(item, i) in librariesList"
        :id="item.id + i"
        :key="item.id + i"
        v-intersect.once="onIntersect"
        :style="showCollapsedItems && i > collapsedItemsAmount - 1 && 'display:none;'"
        @click="navigate($event, item)">
        <!-- link to collection -->
        <component
          :is="createAnchorForCollections ? 'router-link' : 'div'"
          :to="item.route || ''"
          :href="item.route || ''"
          style="width: 100%;"
          @click.native.prevent>
          <AppCard
            :item="item"
            v-bind="$attrs">
            <template #actionSlot="{ item }">
              <slot
                name="actions"
                :item="item" />
            </template>
          </AppCard>
          <!-- badge to show unread comments -->
          <!-- <v-badge
            v-show="showUnreadComments && item.commentsUnread"
            class="collections__unread-comments-badge"
            :content="item.commentsUnread" /> -->
          <!-- <div
            v-if="item.publishExpirationDate"
            class="expired-collection-icon">
            <v-badge
              content="!" />
          </div> -->
        </component>
      </div>
    </div>

    <!-- skeleton -->
    <div
      v-else-if="useLazyLoading && isFetching"
      class="sourcery__grid">
      <AppCardSkeleton
        v-for="index in 4"
        :key="index" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import AppCard from '@/components/App/AppCard';
import AppCardSkeleton from '@/components/App/AppCardSkeleton';

export default {
  name: 'ListingImageItem',
  components: {
    AppCard,
    AppCardSkeleton,
  },
  props: {
    collapsedItemsAmount: {
      type: Number,
      default: 4,
    },
    counter: {
      type: Object,
      default: () => ({
        prop: 'collectionCount',
        type: 'collections',
      }),
    },
    intersect: {
      type: Boolean,
      default: () => false,
    },
    librariesList: {
      type: Array,
      default: () => [],
    },
    showCollapsedItems: {
      type: Boolean,
      default: false,
    },
    showUnreadComments: {
      type: Boolean,
      default: false,
    },
    showCounter: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: () => null,
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('FeatureFlags', [
      'createAnchorForCollections',
      'useLazyLoading']),
  },
  methods: {
    navigate($event, item) {
      if (this.createAnchorForCollections && item.route) {
        // this will be automatically redirected using the router link in template
      } else {
        $event.preventDefault();
        this.$emit('navigate', item);
      }
    },
    onIntersect(entries, observer, isIntersecting) {
      if (!this.intersect) {
        return;
      }
      this.$emit('onIntersect', {
        entries, observer, isIntersecting,
      });
    },
    renderAmount(item) {
      let count = item[this.counter.prop];
      if (!count || count < 0) {
        count = 0;
      }
      return `${count} ${this.counter.type}`;
    },
  },
};
</script>
