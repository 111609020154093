<!--
  This component displays the card used to display collections and products
-->
<template>
  <div class="app-card d-flex flex-column align-start pa-3">
    <div class="app-card__image-wrapper">
      <div class="app-card__image">
        <!-- images -->
        <ListingImage
          :collection="item"
          :pictures="getPictures"
          :watch-for-pictures="source === 'search'" />
      </div>

      <!-- options menu -->
      <span
        v-if="$scopedSlots.actionSlot"
        class="app-card__options">
        <slot
          name="actionSlot"
          :item="item" />
        <AppVerifiedMark
          :is-verified="item.isVerified"
          :child-verified-ws="item.childVerified"
          :verified-page-id="item.verifiedPageId"
          :verified-page-name="item.verifiedPageName" />
      </span>
    </div>
    <div class="app-card__label d-flex flex-column justify-center align-center align-self-stretch gap-1 text-center">
      <span class="fs-18 font-fam-poppins font-weight-bold text-capitalize black--text">
        {{ source === 'search' ? item.title : item.name }}
      </span>
      <span
        v-if="isLibrary"
        class="fs-16 darkGrey--text">
        {{ item.collectionCount + ' Collections' }}
      </span>
      <span
        v-if="showCollectionName"
        class="font-collection-name">
        Collection: {{ item.collectionName }}
      </span>
    </div>
  </div>
</template>

<script>
import ListingImage from '@/components/Listing/ListingImageItem/ListingImage';

import SetPictures from '@/mixins/SetPictures';

export default {
  name: 'AppCard',
  components: {
    ListingImage,
    AppVerifiedMark: () => import('@/components/App/AppVerifiedMark'),
  },
  mixins: [SetPictures],
  props: {
    isLibrary: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    showCollectionName: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: '',
    },
  },
  computed: {
    getPictures() {
      return this.source === 'search'
        ? this.getAttachments()
        : this.setPictures(this.item);
    },
  },
  methods: {
    handleClick($event) {
      console.log($event);
      $event.stopPropogation();
    },
    getAttachments() {
      const { item } = this;
      const { attachment, logo: key } = item;
      let attachments = attachment.map(item => ({
        attachment: item,
      }));
      attachments = [
        ...attachments,
        ...(key ? [{
          key,
          isLogo: true,
        }] : []),
      ];
      return attachments;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-card {
  border-radius: 15px;
  border: 1px solid rgba(12, 12, 12, 0.25);

  &__image-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 305 / 201;
  }

  &__image {
    width: 100%;
    aspect-ratio: 305 / 201;
    border-radius: 15px;
    overflow: hidden;
  }

  &__options {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  &__label {
    padding: 16px 4px 4px;
    word-break: break-all;
  }
}
</style>