<template>
  <div class="listing-item-skeleton">
    <v-skeleton-loader
      class="listing-item-skeleton__image"
      type="image" />
    <v-skeleton-loader
      class="listing-item-skeleton__text"
      type="text" />
  </div>
</template>

<script>
export default {
  name: 'AppCardSkeleton',
};
</script>

<style scoped lang="scss">
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
  .v-skeleton-loader__text {
    height: 100%;
  }
}

.listing-item-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    width: 100%;
    height: 200px;
    margin-bottom: 15px;
    border-radius: 15px;
  }

  &__text {
    width: 200px * 2/3;
    height: 1rem;
  }
}
</style>