<template>
  <div class="listing-libraries">
    <!-- main list -->
    <ListingImageItem
      :is-fetching="isFetchingLibraries"
      :libraries-list="updatedCustomLibraries"
      is-library
      @navigate="navigate">
      <template #actions="{ item }">
        <span
          class="sourcery__icon-wrapper white"
          @click.stop.prevent="$emit('click-action-menu', {
            item,
            event: $event,
          })"
          v-on="$listeners">
          <v-icon color="black">
            mdi-dots-horizontal
          </v-icon>
        </span>
      </template>
    </ListingImageItem>

    <!-- empty page -->
    <AppEmptyList
      v-if="!isFetchingLibraries && !updatedCustomLibraries.length"
      from="libraries"
      class="mt-12" />
  </div>
</template>
<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import ListingImageItem from '../ListingImageItem';
export default {
  name: 'Libraries',
  components: {
    ListingImageItem,
    AppEmptyList: () => import('@/components/App/AppEmptyList'),
  },
  data() {
    return {
      updatedCustomLibraries: [],
    };
  },
  computed: {
    ...mapState('Libraries', ['isFetchingLibraries']),
    ...mapGetters('FeatureFlags', ['createAnchorForCollections']),
    ...mapGetters('Libraries', ['getSortedLibraries']),
  },
  async created() {
    await this.addRoute();
    // watch for further changes
    this.$watch('getSortedLibraries', async () => {
      await this.addRoute();
    });
  },
  methods: {
    async addRoute() {
      if (!this.createAnchorForCollections) {
        this.updatedCustomLibraries = this.getSortedLibraries;
        return;
      }
      const updatedLib = this.getSortedLibraries.map((item) => {
        const { id } = item;
        const route = this.$router.resolve({
          name: id === 'community' ? 'community-collections' : 'collections',
          params: {
            id,
          },
        }).href;
        return {
          ...item,
          route,
        };
      });
      this.updatedCustomLibraries = updatedLib;
    },
    navigate(item) {
      const { id } = item;
      this.$router.push({
        name: id === 'community' ? 'community-collections' : 'collections',
        params: {
          id,
        },
      });
    },
  },
};
</script>
